import { useModel as _useModel, mergeModels as _mergeModels } from 'vue'
import { unref as _unref, vModelDynamic as _vModelDynamic, mergeProps as _mergeProps, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["data-tooltip"]
const _hoisted_2 = ["id", "name", "placeholder", "autocomplete"]
const _hoisted_3 = ["for"]
const _hoisted_4 = {
  key: 0,
  class: "fas fa-question-circle tooltip-icon"
}
const _hoisted_5 = { class: "tooltip" }
const _hoisted_6 = {
  key: 1,
  class: "error-message aca-error-message",
  role: "alert",
  "aria-live": "assertive"
}

import { ref, onMounted } from 'vue';
    import { debounce } from 'lodash';
    import { useAnimation } from '@composables/animation';
    import { useI18next } from '@composables/i18next';
    import { RenderlessValidator } from 'rocketship-validator';

    
export default /*@__PURE__*/Object.assign({ inheritAttrs: false }, {
  __name: 'InputWithValidationWithTooltip',
  props: /*@__PURE__*/_mergeModels({
        error: {
            type: String,
            required: true,
        },
        hasError: {
            type: Boolean,
            required: false,
            default: false,
        },
        label: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        validator: {
            type: Object,
            required: true,
        },
    }, {
    "modelValue": { type: String, required: true },
    "modelModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(['validation'], ["update:modelValue"]),
  setup(__props, { emit: __emit }) {

    
    const emit = __emit;
    

    const { t, vT } = useI18next('profile');
    const { animated } = useAnimation();

    const field = ref(null);
    const touched = ref(false);

    const tempProfile = ref({});
    const onInput = debounce((event) => {
        touched.value = true;
        validate();
    }, 600);

    const isValid = ref(false);

    const validate = async () => {
        if (!model.value && !touched.value) return;
        tempProfile.value[__props.name] = model;
        await field.value.validate();
        isValid.value = field.value.isValid;
        emit('validation', { name: __props.name, isValid: field.value.isValid });
    };

    const model = _useModel(__props, "modelValue");

    onMounted(() => {
        if ((model.value !== undefined && model.value !== '') || !__props.validator.required) {
            touched.value = true;
            validate();
        }
    });

return (_ctx, _cache) => {
  return (_openBlock(), _createBlock(_unref(RenderlessValidator), {
    ref_key: "field",
    ref: field,
    guards: { [__props.name]: __props.validator },
    data: tempProfile.value
  }, {
    default: _withCtx(({
            isError,
        }) => [
      _createElementVNode("div", {
        class: _normalizeClass(["base-input field", { error: isError(__props.name) && touched.value || __props.hasError, animated: _unref(animated), valued: Boolean(model.value), valid: isValid.value }]),
        "data-tooltip": _unref(t)(`field.${__props.name}_tooltip`)
      }, [
        _withDirectives(_createElementVNode("input", _mergeProps(_ctx.$attrs, {
          id: __props.name,
          "onUpdate:modelValue": [
            _cache[0] || (_cache[0] = $event => ((model).value = $event)),
            _cache[2] || (_cache[2] = (...args) => (_unref(onInput) && _unref(onInput)(...args)))
          ],
          name: __props.name,
          placeholder: _unref(animated) ? '' : __props.label,
          autocomplete: __props.name,
          onInput: _cache[1] || (_cache[1] = (...args) => (_unref(onInput) && _unref(onInput)(...args)))
        }), null, 16, _hoisted_2), [
          [_vModelDynamic, model.value]
        ]),
        _createElementVNode("label", { for: __props.name }, _toDisplayString(__props.label), 9, _hoisted_3),
        (!isError(__props.name))
          ? (_openBlock(), _createElementBlock("i", _hoisted_4, [
              _withDirectives(_createElementVNode("div", _hoisted_5, null, 512), [
                [_unref(vT), `field.${__props.name}_tooltip`]
              ])
            ]))
          : _createCommentVNode("", true),
        (isError(__props.name))
          ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(__props.error), 1))
          : _createCommentVNode("", true)
      ], 10, _hoisted_1)
    ]),
    _: 1
  }, 8, ["guards", "data"]))
}
}

})