import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  class: "modal promotion-optin",
  role: "dialog",
  "aria-dialog-name": "promotion optin"
}
const _hoisted_2 = { class: "p-s pt-m" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "has-background-bright-blue-50" }
const _hoisted_6 = { class: "p-s" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_PromotionOptin = _resolveComponent("PromotionOptin")

  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay",
    onClick: _cache[1] || (_cache[1] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h2", {
          class: "headline-copy-med",
          innerHTML: $setup.t('promotions:optin_modal.headline')
        }, null, 8, _hoisted_3),
        _createElementVNode("p", {
          innerHTML: $setup.t('promotions:optin_modal.body')
        }, null, 8, _hoisted_4)
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.activePromotions, (promo, index) => {
          return (_openBlock(), _createBlock(_component_PromotionOptin, {
            key: index,
            data: promo,
            class: _normalizeClass(`optin-${index}`),
            onUpdate: $options.updateValue
          }, null, 8, ["data", "class", "onUpdate"]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("button", {
          class: "button",
          onClick: _cache[0] || (_cache[0] = _withModifiers($event => ($options.sendOptins()), ["prevent"]))
        }, _toDisplayString($setup.t('buttons:continue')), 1)
      ])
    ])
  ]))
}