import { unref as _unref, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, vShow as _vShow, createVNode as _createVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "error error-message"
}
const _hoisted_2 = { name: "user_info" }
const _hoisted_3 = ["disabled"]
const _hoisted_4 = { class: "password-link" }

import { reactive, ref, inject, computed } from 'vue';
    import { useRouter } from 'vue-router';
    import { useI18next } from '@composables/i18next';
    import { useStore } from 'vuex';
    import { RenderlessValidator } from 'rocketship-validator';
    import { InputWithValidation, PasswordInputWithValidation } from '@components/form';
    import { login as validatorGuards } from '../../../lib/validation/client-validators';

    import { setRecaptchaV3Challenge } from '../recaptcha';
    import openModal, { ErrorModal, ForgotPasswordModal, BrandSelectionModal } from '../modals';

    
export default {
  __name: 'LoginForm',
  props: {
        hideInputs: {
            type: Boolean,
            default: false,
        },
    },
  emits: ['logged-in', 'not-found', 'reg', 'close-modal'],
  setup(__props, { emit: __emit }) {


    const { t, vT } = useI18next(['profile', 'login', 'buttons']);

    const emit = __emit;
    const props = __props;

    const store = useStore();
    const profile = computed(() => store.state.profile);
    const router = useRouter();
    const hasErrorsPassword = ref(false);
    const hasErrorsId = ref(false);


    const tempProfile = reactive({
        email: '',
        plainTextPassword: '',
        ...store.state.profile,
    });

    const loading = ref(0);
    const webAnalytics = inject('$webAnalytics');

    const noEmail = /^((?!@).)*$/;

    const isButtonDisabled = computed(() => (
        !tempProfile.email ||
        noEmail.test(tempProfile.email) ||
        !tempProfile.plainTextPassword
    ));

    const doLogin = async () => {

        // Remove old token from login when coming back from an expired session
        tempProfile.token = store.state.profile.token;

        tempProfile.x_channel = store.state.app.channel || undefined;
        tempProfile.affiliate_id = store.state.app.affiliateId || undefined;

        await setRecaptchaV3Challenge({ data: tempProfile, action: 'login' });


        webAnalytics.track('Valid Submission', { category: 'Login Form' });

        loading.value = true;

        try {
            await store.dispatch('profile/logIn', { tempProfile });
            loading.value = false;

            if (!profile.value.preferred_brand) {
                openModal(BrandSelectionModal);
            }

            if (router.currentRoute.value.name?.includes('landing') || router.currentRoute.value.name === 'signin' || router.currentRoute.value.name === 'registration') {
                router.replace('/');
            }
            emit('logged-in');

            window.dataLayer.push({
                'event': 'login',
                'email_id': tempProfile.email,
            });

        }
        catch (error) {
            loading.value = false;

            const { response } = error;

            if (response?.status === 404) {
                await store.dispatch('profile/updateProfile', tempProfile);

                hasErrorsId.value = true;
                hasErrorsPassword.value = false;
            }
            else if (response?.status === 401) {
                hasErrorsId.value = false;
                hasErrorsPassword.value = true;
            }
            else if (response.data?.errors) {
                // validator.setAllErrors(response.data.errors);
                hasErrorsId.value = false;
                hasErrorsPassword.value = false;
            }
            else {
                openModal(ErrorModal);
                hasErrorsId.value = false;
                hasErrorsPassword.value = false;
            }
        }
    };

    const openForgotPassword = async () => {
        emit('close-modal');
        openModal(ForgotPasswordModal);
        return;
    };


return (_ctx, _cache) => {
  const _directive_track = _resolveDirective("track")

  return (_openBlock(), _createBlock(_unref(RenderlessValidator), {
    ref: "validator",
    guards: _unref(validatorGuards),
    data: tempProfile,
    class: "login-form"
  }, {
    default: _withCtx(({
            hasErrors,
        }) => [
      _withDirectives(_createElementVNode("h2", null, null, 512), [
        [_unref(vT), 'login:headline_copy']
      ]),
      _withDirectives(_createElementVNode("p", null, null, 512), [
        [_unref(vT), 'login:body_copy']
      ]),
      _createElementVNode("form", {
        id: "login_form",
        name: "login_form",
        autocomplete: "",
        novalidate: "",
        "data-e2e": "login-form",
        onSubmit: _withModifiers(doLogin, ["prevent"])
      }, [
        (profile.value.duplicate_account)
          ? _withDirectives((_openBlock(), _createElementBlock("p", _hoisted_1, null, 512)), [
              [_unref(vT), { key: 'error_duplicate', email: profile.value.email }]
            ])
          : _createCommentVNode("", true),
        _withDirectives(_createElementVNode("p", {
          class: "error error-message",
          "data-e2e": "login-form-error"
        }, _toDisplayString(_unref(t)('error_copy')), 513), [
          [_vShow, hasErrors]
        ]),
        _createElementVNode("fieldset", _hoisted_2, [
          _createVNode(_unref(InputWithValidation), {
            modelValue: tempProfile.email,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((tempProfile.email) = $event)),
            type: props.hideInputs ? 'hidden' : 'email',
            error: _unref(t)('ada.errors.email'),
            label: "Email Address",
            name: "email",
            validator: _unref(validatorGuards).email,
            onValidation: _ctx.handleValidation
          }, null, 8, ["modelValue", "type", "error", "validator", "onValidation"]),
          _createVNode(_unref(PasswordInputWithValidation), {
            modelValue: tempProfile.plainTextPassword,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((tempProfile.plainTextPassword) = $event)),
            type: props.hideInputs ? 'hidden' : 'email',
            error: _unref(t)('ada.errors.password'),
            label: "Password",
            name: "plainTextPassword",
            validator: _unref(validatorGuards).plainTextPassword,
            onValidation: _ctx.handleValidation
          }, null, 8, ["modelValue", "type", "error", "validator", "onValidation"]),
          _withDirectives(_createElementVNode("p", {
            class: "error error-message",
            "data-e2e": "login-form-error"
          }, _toDisplayString(_unref(t)('error_copy')), 513), [
            [_vShow, hasErrorsId.value]
          ]),
          _withDirectives(_createElementVNode("p", {
            class: "error error-message",
            "data-e2e": "login-form-error"
          }, _toDisplayString(_unref(t)('error_pass_copy')), 513), [
            [_vShow, hasErrorsPassword.value]
          ])
        ]),
        _createElementVNode("button", {
          type: "submit",
          class: _normalizeClass(["btn btn_login", { loading: loading.value }]),
          disabled: isButtonDisabled.value || loading.value,
          "data-e2e": "login-form-submit"
        }, _toDisplayString(_unref(t)('buttons:sign_in')), 11, _hoisted_3)
      ], 32),
      _createElementVNode("p", _hoisted_4, [
        _withDirectives(_createElementVNode("a", {
          href: "#",
          "data-e2e": "forgot-password-link",
          onClick: _withModifiers(openForgotPassword, ["prevent"])
        }, null, 512), [
          [_unref(vT), 'login:password_link']
        ])
      ]),
      _cache[3] || (_cache[3] = _createElementVNode("hr", null, null, -1)),
      _createElementVNode("p", null, _toDisplayString(_unref(t)('login:not_registered')), 1),
      _createElementVNode("p", null, [
        _withDirectives((_openBlock(), _createElementBlock("a", {
          href: "#",
          "data-e2e": "register-link",
          onClick: _cache[2] || (_cache[2] = _withModifiers($event => (_ctx.$emit('reg')), ["prevent"]))
        }, [
          _createTextVNode(_toDisplayString(_unref(t)('login:not_registered_cta')), 1)
        ])), [
          [_directive_track, ['click', { category: 'Login', label: 'not registered' }]]
        ])
      ])
    ]),
    _: 1
  }, 8, ["guards", "data"]))
}
}

}